.garantia {
    background-color: #202020;
    color: #fff;
    padding: 50px 0;
  }
  
  .contentg {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }

  .title7 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5em;
    color: #fff;

    @media screen and (max-width: 768px) {
      font-size: 1.2em;
      margin: 10px 20px;
    }
  }

  .text7 {
    font-family: 'Spectral', sans-serif;
    font-size: 1.2em;
    margin-bottom: 40px;
  
    @media screen and (max-width: 768px) {
      font-size: .8em;
      margin: 20px;
      padding-bottom: 10px;
    }
  }

  .text8 {
    font-family: 'Spectral', sans-serif;
    font-size: 1.2em;
    margin-bottom: 40px;
  
    @media screen and (max-width: 768px) {
      font-size: .8em;
      margin: 20px;
      padding-bottom: 10px;
    }
  
  }

  .imageg img {
    padding-left: 40px;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      max-width: 150px;
      margin-left: 10px;
      padding-left: 0;
    }
  }
  
  .button {
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
    margin: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
  }
  
  .button:hover {
    background-color: #B8860B;
  }


  .button-link {
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
        font-family: 'Spectral', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
    text-decoration: none; /* Adicione esta linha para remover o sublinhado de links */

    @media screen and (max-width: 768px) {
      font-size: .8em;
    }
  }
  
  .button-link:hover {
    background-color: #B8860B;
  }
  