.tela {
    background-color: #202020;
    padding: 50px 0;
    color: #333;
        height: 900px; /* Definindo altura de 100% da viewport */
    display: flex; /* Usando flexbox para alinhar o conteúdo verticalmente */
    align-items: center; /* Alinhando verticalmente */
    overflow-x: hidden;
  }
  
  .contentt {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  
  .textt {
    z-index: 1;
    width: 500px;
    font-family: 'Cinzel', sans-serif;
    color: #fff;
    flex: 1;
    padding-left: 130px;

    @media screen and (max-width: 768px) {
      padding-left: 40px;
      padding-bottom: 200px;
      padding-top: 100px;
      
    }
  }
  
  .textt h2 {
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    margin-bottom: 20px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 40px;
  }
  
  li::before {
    content: "✔";
    margin-right: 10px;
    color: #B8860B;
    font-size: 24px;
  }
  
  .imaget {
    height: 100vh;
margin-top: -100px;
    text-align: center;
    height: 900px; /* Faz a imagem ocupar toda a altura do container */
    background-size: cover; /* Ajusta a imagem para cobrir todo o espaço */
    background-position: center; /* Centraliza a imagem */
  


  }
  
  .imaget img {
 /* Garante que a imagem não ultrapasse o container */
    height: 100vh;

  }
  
  @media screen and (max-width: 768px) {
    .tela {
      height: 590px; /* Removendo altura fixa para ajustar à altura do conteúdo */
      padding: 20px 0; /* Ajuste o espaçamento conforme necessário */
    }
  
    .textt h2 {
      width: 290px;
      font-size: 32px; /* Reduzindo o tamanho da fonte */
    }
  
    li::before {
      font-size: 18px; /* Reduzindo o tamanho dos símbolos */
    }
  
    .imaget img {
      max-width: 900px; /* Ajustando a imagem para ocupar toda a largura disponível */
      height: auto; /* Permitindo que a altura seja ajustada automaticamente */
    }
  }


  .mobileImage {
    position: absolute;
    bottom: 0;
    top: 250px;
    left: 0;
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: flex-end;
  }
  
  .mobileImage img {
    width: 100%;
  }


  .button-linka9 {
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
        font-family: 'Spectral', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
    text-decoration: none; /* Adicione esta linha para remover o sublinhado de links */
  
    @media screen and (max-width: 768px) {
      font-size: .8em;
    }
  
  }
  
  .button-linka9:hover {
    background-color: #B8860B;
  }