.caixa {
    background-color: #202020;
    padding: 20px 0;
    color: #333;
  }
  
  .container-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0px;
  }
}
  
  .container2 {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 400px;
    margin: 20px auto;

    @media screen and (max-width: 768px) {
      width: 90%;
    margin: 20px auto;
      padding: 10px;
      }

  }
  
  .check-symbol {
    color: #D4AF37;
    font-size: 35px;
    margin-right: 10px;
  }
  
  .container-text2 {
    font-family: 'Spectral', sans-serif;
    color: #000;
    font-size: 1.2em;

    @media screen and (max-width: 768px) {
      font-size: .8em;
    }

  }
  
  .section-title2 {
    font-family: 'Playfair Display', serif;
    color: #fff;
    font-size: 2.5em;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }
  }

  .button-linke {
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
        font-family: 'Spectral', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
    text-decoration: none; /* Adicione esta linha para remover o sublinhado de links */

    @media screen and (max-width: 768px) {
      font-size: .8em;
    }
  }
  
  .button-linke:hover {
    background-color: #B8860B;
  }
  