.container {
    background-color: #202020;
    padding: 20px 0;
    color: #333;
  }
  
  .content2 {
    padding-bottom: 20px;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
  
  .section-title9 {
    font-family: 'Playfair Display', serif;
    color: #fff;
    font-size: 2.5em;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  
  .rounded-container {
    overflow: hidden;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto; /* Alterado para margin: 20px auto; para centralizar */
    max-width: 800px; /* Define uma largura máxima para o container */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Para alinhar os itens centralizados com espaço entre eles */
  
    @media screen and (max-width: 768px) {
      display: grid;
      padding: 0px;
      max-width: 100%;
      margin: 20px;
    }
  
  }
  
  .image-container {
    padding-left: 200px;
    text-align: left;
    margin-left: -175px; /* Adicionado para criar a margem da borda do container */
 /* Adicionado para alinhar a imagem no topo do container */

 @media screen and (max-width: 768px) {

    text-align: center;
    margin-left: -205px; /* Isso ainda pode ser ajustado conforme necessário */
    display: flex;
    justify-content: center;
}

  }
  
  .image-container img {
    max-width: 200px;
    border-radius: 10px;
    height: 200px;

    @media screen and (max-width: 768px) {
      display: flex;
      padding: 0px;
      max-width: 100%;
      margin: 20px;
      align-items: center;
    }
  }
  
  
  .text-container22 {
    padding-left: 20px;
    text-align: left;


    @media screen and (max-width: 768px) {
      margin: auto 20px;
    }
  }
  
  .container-title {
    font-family: 'Playfair Display', serif;
    font-size: 1.8em;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  
  .container-text {
    font-family: 'Spectral', sans-serif;
    font-size: 1em;

    @media screen and (max-width: 768px) {
      font-size: .8em;
    }

  }
  
  .centered-image {
    margin-top: 40px;
  }
  
  .centered-image img {
    max-width: 100%;
    height: auto;
  }
  
  .text3 {
    font-family: 'Spectral', sans-serif;
    max-width: 700px;
    color: white;
    font-size: 1.2em;
    margin-bottom: 30px;
    margin-left: auto; /* Define a margem esquerda para automático (centralizado) */
    margin-right: auto; /* Define a margem direita para automático (centralizado) */
    text-align: center; /* Alinha o texto ao centro */
  
    @media screen and (max-width: 768px) {
      font-size: 1em;
      margin-left: auto; /* Define a margem esquerda para automático (centralizado) */
      margin-right: auto; /* Define a margem direita para automático (centralizado) */
      padding: 0 20px;
    }
  }

  .bold2 {
    font-weight: bold;
    font-size: 1.3em;
    color: #000000;
}

.normal2 {
    font-size: 1.2em;
    color: #000000;
}

.button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  margin: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
}

.button:hover {
  background-color: #555;
}

.button-links {
  background-color: #D4AF37;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
      font-family: 'Spectral', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
  text-decoration: none; /* Adicione esta linha para remover o sublinhado de links */


  @media screen and (max-width: 768px) {
    font-size: .8em;
  }
}

.button-links:hover {
  background-color: #B8860B;
}
