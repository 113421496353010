.arte {
    background-color: #F0F0F0;
    color: #fff;
    padding: 20px 0;
}

.content {
    max-width: 1000px;
    margin: 20px auto;
    text-align: center;
}

.title3 {
    font-family: 'Playfair Display', serif;
    max-width: 1000px;
    font-size: 2.5em;
    color: #000;

    @media screen and (max-width: 800px) {
        font-size: 1.2em;
    }
}


.images-containerd {
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px; /* Espaçamento entre as imagens */
}

.images-containerd img {
    max-width: 100%;
}

@media screen and (max-width: 800px) {
    .images-containerd {
        grid-template-columns: repeat(1, 1fr);
        max-width: 100%;
    }

    .images-containerd img {
        width: 450px;
        margin: 0 auto; /* Centraliza a imagem */
        display: block; /* Remove espaços extras abaixo da imagem */
        max-height: 100%; 
    }
}

.image img {
    max-width: 100%;

    @media screen and (max-width: 800px) {
        width: 100px;
    }

}

.button-linkaaa {
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
        font-family: 'Spectral', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
    text-decoration: none; /* Adicione esta linha para remover o sublinhado de links */


    @media screen and (max-width: 768px) {
        font-size: .8em;
      }
  }
  
  .button-linkaaa:hover {
    background-color: #B8860B;
  }
  