.promo {
    background-color: #f2f2f2;
    padding: 30px 0;
    color: #333;
  }
  
  .contentp {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
  
  .rounded-containerp {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: inline-block; /* Centraliza o container */
  }
  
  .title6 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5em;
    color: #000;

    @media screen and (max-width: 768px) {
      font-size: 1.4em;
    }

  }

  .titlep {
    font-family: 'Playfair Display', serif;
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 10px;

    
    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  
  .discounted {
    text-decoration: line-through;
  }
  
  .price {
    font-size: 20px;
    font-weight: bold;
  }
  
  .checklist {
    text-align: center;
    margin-top: 20px;
    list-style-type: none;
    padding: 0;
  }
  
  .checklist li {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .checklist li:before {
    content: '✓';
    color: #D4AF37;
    margin-right: 5px;
  }
  
  .contentp2 {
    max-width: 1000px;
    margin: 30px auto;
    padding-top: 10px;
    text-align: center;
    align-items: center;
  }


  .button-linkf {
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
        font-family: 'Spectral', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
    text-decoration: none; /* Adicione esta linha para remover o sublinhado de links */

    @media screen and (max-width: 768px) {
      font-size: .8em;
    }
  }
  
  .button-linkf:hover {
    background-color: #B8860B;
  }
  