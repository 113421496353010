.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #F0F0F0;
  }
  
  .content-container {
    max-width: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex; /* Adicionando o display flex */
    flex-direction: row; /* Definindo a direção da flexbox */
    justify-content: center; /* Centralizando os itens horizontalmente */
  }
  
  .text-container {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 750px;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
padding-top: 150px;
      margin-bottom: 0px;
    }

    @media screen and (max-height: 730px) {
      padding-top: 120px;
            margin-bottom: 0px;
          }
  }
  
  .image-containerH {
    max-width: 100%;

    overflow-x: hidden;
  }
  
  
  .imageo img {
    margin: 0 100px;
    max-width: 100%;
    max-height: 98%;
  }

  .titleh {
    font-family: 'Playfair Display', serif;
    font-size: 1.8em;
    color: #000;

    @media screen and (max-width: 800px) {
      font-size:1em;
    }
  }
  
  .text {
    font-family: 'Spectral', sans-serif;
    font-size: 1.2em;
    color: #000;

    @media screen and (max-width: 768px) {
      font-size: 1em;
    }
  }
  
  .bold {
    font-family: 'Spectral', sans-serif;
    font-weight: bold;
    font-size: 1.3em;
    color: #000;
  }
  
  .normal {
    font-family: 'Spectral', sans-serif;
    font-size: 1.2em;
    color: #000;
  }

  @media screen and (max-width: 1200px) {
    .content-container {
      height: 920px;
      flex-direction: column;
      align-items: center;
      text-align: center;

    }
  
    .image-containerH {
      max-width: 450px;
      padding-left: 0;
      margin: 10px auto;
    }
  
    .imageo img {
      margin: 0;
      max-width: 100%;
      height: auto;
    }
  }
  

  .text-container .button-link {
    text-decoration: none;
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
    font-family: 'Spectral', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);

    @media screen and (max-width: 768px) {
      font-size: .8em;
    }

  }
  
  .text-container .button-link:hover {
    background-color: #B8860B;
  }
  

  .text-container button {
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    margin: 25px;
    font-size: 1.2em;
  
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
  }
  
  .text-container button:hover {
    background-color: #B8860B;
  }