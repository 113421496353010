.navbar {
  z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    transition: background-color 0.3s;
  }
  
  .navbar.scrolled {
    background-color: #202020;
  }
  
  .logo img {
    padding-top: 30px;
    height: 240px;
    transition: height 0.5s;
    padding-left: 160px;
  }
  
  .logo img.small-logo {
    padding-top: 0px;
    height: 80px;
    padding-left: 160px;
    transition: 0.3s;
  }
  
  .logo:hover {
    cursor: pointer;
  }

  .instagram-button a {
    position: absolute;
    top: 15px;
    right: 0;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    padding-right: 150px;
  }
  
  .instagram-button a:hover {
    color: #333;
  }
  
  @media screen and (max-width: 768px) {
    .navbar {
      padding: 0 10px; /* Ajuste o espaçamento conforme necessário */
    }
  
    .logo img {
      height: 100px; /* Ajuste a altura conforme necessário */
      padding-left: 20px; /* Remova o padding à esquerda */
    }
  
    .logo img.small-logo {
      height: 50px; /* Ajuste a altura conforme necessário */
      padding-left: 20px; /* Remova o padding à esquerda */
    }
  
    .instagram-button a {
      transform: scale(0.8);
      top: 2px;
      right: 10px; /* Ajuste a posição conforme necessário */
      padding-right: 30px; /* Remova o padding à direita */
    }
  }