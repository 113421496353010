.feed {
    background-color: #f2f2f2;
    color: #fff;
    padding: 50px 0;
  }
  
  .content3 {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }

  .title5 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5em;
    color: #000;

    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }

  }

  .image img {
    max-width: 100%;
  }
  
  .carousel img {
    max-width: 90%;
    height: auto;
  }
  
  .carousel {
    .slick-slider {
      position: relative;
      padding-bottom: 5%;
      padding-left: 5%;
      /* Define a proporção da altura em relação à largura das imagens */
    }


  
    .slick-slide {
      transition: transform 0.3s ease-in-out;
      transform: scale(1);
    }
  
    .slick-center {
      transform: scale(1.2); /* Aumenta o tamanho do slide central */
      transition: transform 0.3s ease-in-out;
    }
  
    .slick-prev,
    .slick-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 0px;
      cursor: pointer;
      color: #fff;
      background-color: none;
      padding: 10px;
      border: none;
      border-radius: 50%;
      transition: background-color 0.3s ease-in-out;
    }
  
    .slick-prev:hover,
    .slick-next:hover {
      background-color: none;
    }
  
    .slick-prev {
      left: 10px;
    }
  
    .slick-next {
      right: 10px;
    }
  
    .slick-dots {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .slick-dots li {
      margin: 0 5px;
    }
  
    .slick-dots li button {
      font-size: 12px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
    }
  
    .slick-dots li button:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  
    .slick-dots li.slick-active button {
      background-color: #fff;
      color: #000;
    }
  }
  
  .button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
    margin: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
  }
  
  .button:hover {
    background-color: #555;
  }

  .bold {
    font-weight: bold;
    font-size: 1.3em;
    color: #fff;
  }
  
  .normal {
    font-size: 1.2em;
    color: #fff;
  }


  .button-linkc {
    background-color: #D4AF37;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
        font-family: 'Spectral', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
    text-decoration: none; /* Adicione esta linha para remover o sublinhado de links */

      @media screen and (max-width: 768px) {
        font-size: .8em;
      }
  }
  
  .button-linkc:hover {
    background-color: #B8860B;
  }
  